import React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { PageProps } from "gatsby"

import LayoutMdx from "../components/LayoutMdx"

import { Seo } from "../components/Seo/Seo"
import {
  Navigation,
  Blog,
  Footer,
  Hero,
  Breadcrumb,
} from "../components/Marketing"
import { ArticleEntity } from "../entities/ArticleEntity"
import { Separator } from "../components/article"
import { getImage } from "gatsby-plugin-image"

const ArticlePage: React.FC<PageProps<
  any,
  ArticleEntity & { more: ArticleEntity[] }
>> = props => {
  const article = props.pageContext
  const categories = article.categories
  const illustration = getImage(article.image)

  const breadcrumbs: Array<{
    label: string
    url: string
  }> = categories.map(category => ({ label: category.name, url: category.url }))

  if (!article.isCategory)
    breadcrumbs.push({ label: article.display__title, url: article.url })

  return (
    <LayoutMdx>
      <Seo
        ads
        article={{ ...article, image: illustration.images.fallback.src }}
        image={illustration.images.fallback.src}
        title={article.seo__title}
        description={article.seo__description}
        breadcrumbs={breadcrumbs}
      />
      <Navigation />
      <Hero
        theme={{
          as: "nav",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          raw: true,
          value: article.title,
        }}
        description={{
          as: "p",
          raw: true,
          value: article.description,
        }}
        author={article.author}
        date={{
          created_at: article.created_at,
          updated_at: article.updated_at,
        }}
        reading={article.reading}
        illustration={illustration}
      />

      <Separator />

      <MDXRenderer>{article.html}</MDXRenderer>

      <Separator />

      <Blog
        title={{
          as: "h4",
          value: (
            <>
              En savoir plus sur le{" "}
              <span className="text-green-600">haïku</span> ?
            </>
          ),
        }}
        description={
          <>
            Nous publions régulièrement des articles autour de l'histoire du
            haïku, des aides et des inspirations pour la conceptions des haïkus.
          </>
        }
        articles={props.pageContext.more.map(article => ({
          title: article.display__title,
          description: article.seo__description,
          author: article.author,
          id: article.url,
          url: article.url,
          image: article.thumb,
          category: article.categories[0].name,
          date: article.created_at,
          reading: article.reading,
        }))}
      />
      <Footer />
    </LayoutMdx>
  )
}

export default ArticlePage
